import React from "react"
import styles from "./Calendly.module.css"
import { ArrowRight } from "react-feather"
import { Link } from "gatsby"

const Calendly = ({title, subtitle, CTALink, CTACopy}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperContent}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.subtitle}>{subtitle}</p>
        <a target="_blank" className={styles.link} href={CTALink}>{CTACopy}</a>
      </div>
      <div className={styles.bgDetails} />
      <div className={styles.bgDetails2}  />
    </div>
  );
}

// Specifies the default values for props:
Calendly.defaultProps = {
  title: 'Not sure what you need?',
  subtitle: 'Get the design & development support you need to take advantage of the estimated 1.8 billion worldwide online shoppers.',
  CTALink: 'https://calendly.com/undefined-siebe/discovery-call',
  CTACopy: 'Book a free 15 min consultation'
};


export default Calendly
