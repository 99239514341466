import React from "react"
import styles from "./ServiceHeader.module.css"
import { Link } from "gatsby"

const ServiceHeader = ({ image , title , description}) => {
  return (
    <div className={styles.introContainer}>
      <div className={styles.introContent}>
        <h1 className={styles.staticTitle}>{ title }</h1>
        <p className={styles.staticSubTitle}>{ description }</p>
        <Link to="/contact" className={styles.btn}>Start Now</Link>
      </div>
      <div className={styles.introImage} style={{backgroundImage: `url(${image}`}}/>
    </div>
  );
}

export default ServiceHeader