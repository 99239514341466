import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styles from "./service.module.css"
import StartAProject from "../components/StartAProject/StartAProject.js"
import Calendly from "../components/Calendly/Calendly.js"
import { Link } from "gatsby"
import SEO from "../components/seo"
import ServiceHeader from "../components/ServiceHeader/ServiceHeader.js"
import ServiceHeaderAlt from "../components/ServiceHeaderAlt/ServiceHeaderAlt.js"

export default ({ data }) => {
  const service = data.markdownRemark
  const serviceDetails = service.frontmatter
  const intro = serviceDetails.nakedImage ? (
    <ServiceHeader
      image={serviceDetails.nakedImage}
      title={serviceDetails.title}
      description={serviceDetails.description}
    />
  ) : (
    <ServiceHeaderAlt
      image={serviceDetails.image}
      video={serviceDetails.video}
      title={serviceDetails.title}
      description={serviceDetails.description}
    />
  )
  const bodyStyle = serviceDetails.nakedImage
    ? styles.mainBody
    : styles.mainBodyAlt
  return (
    <Layout>
      <SEO title={serviceDetails.title} />
      <div className={styles.introDetails1} />
      <div className={styles.introDetails2} />
      <div className={styles.container}>
        {intro}
        <div
          className={bodyStyle}
          dangerouslySetInnerHTML={{ __html: service.html }}
        />
      </div>
      <Calendly
        title="Discover how we can turn your idea into reality"
        subtitle="Get the design & development support you need to make your big ideas come to light."
      />
      <StartAProject />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        posttype
        description
        nakedImage
        video
      }
      id
    }
  }
`

// 'image' option must be added back in to graphQL query if required
