import React from "react"
import styles from "./ServiceHeaderAlt.module.css"
import { Link } from "gatsby"
import ServiceMedia from "../ServiceMedia/ServiceMedia.js"

const ServiceHeaderAlt = ({ image , video , title , description }) => {
  return (
    <div className={styles.introContainer}>
      <div className={styles.introContent}>
        <h1 className={styles.staticTitle}>{ title }</h1>
        <p className={styles.staticSubTitle}>{ description }</p>
        <Link to="/contact" className={styles.btn}>Start Now</Link>
      </div>
      <ServiceMedia image={image} video={video}/>
    </div>
  );
}

export default ServiceHeaderAlt